import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const NoFavoritesIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="180"
        height="180"
        viewBox="0 0 180 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse cx="89.6849" cy="171.111" rx="48.0912" ry="8.88889" fill="black" />
        <path
            d="M135.837 173.618C137.099 172.823 137.776 171.982 137.776 171.111C137.776 166.202 116.245 162.222 89.6849 162.222C63.1249 162.222 41.5938 166.202 41.5938 171.111C41.5938 171.982 42.2709 172.823 43.5332 173.618C49.3887 169.93 67.8351 167.236 89.6849 167.236C111.535 167.236 129.981 169.93 135.837 173.618Z"
            fill="#666666"
        />
        <path d="M96.4061 109.63H82.332V180H96.4061V109.63Z" fill="url(#paint0_linear_1058_2520)" />
        <path d="M96.4061 109.63H82.332V128.889H96.4061V109.63Z" fill="#BDD6E0" />
        <path
            d="M64.701 121.766L29 86.1576V35.7517L64.701 0H115.107L150.766 35.7517V86.1576L115.107 121.766H64.701Z"
            fill="#DC4633"
        />
        <path
            d="M59.3672 66.6358V63.858L70.0363 49.9383H74.3349L63.8204 63.858L61.8103 63.2407H78.9736V66.6358H59.3672ZM71.2424 71.5432V66.6358L71.3661 63.2407V58.8889H75.1389V71.5432H71.2424Z"
            fill="white"
        />
        <path
            d="M89.6957 71.8519C87.9845 71.8519 86.4382 71.4198 85.0569 70.5556C83.6962 69.6914 82.6242 68.4362 81.8407 66.7901C81.0573 65.1235 80.6656 63.107 80.6656 60.7407C80.6656 58.3745 81.0573 56.3683 81.8407 54.7222C82.6242 53.0556 83.6962 51.7901 85.0569 50.9259C86.4382 50.0617 87.9845 49.6296 89.6957 49.6296C91.4275 49.6296 92.9737 50.0617 94.3344 50.9259C95.6951 51.7901 96.7672 53.0556 97.5506 54.7222C98.3547 56.3683 98.7567 58.3745 98.7567 60.7407C98.7567 63.107 98.3547 65.1235 97.5506 66.7901C96.7672 68.4362 95.6951 69.6914 94.3344 70.5556C92.9737 71.4198 91.4275 71.8519 89.6957 71.8519ZM89.6957 68.3642C90.7059 68.3642 91.5821 68.0967 92.3243 67.5617C93.0665 67.0062 93.6438 66.1626 94.0561 65.0309C94.489 63.8992 94.7055 62.4691 94.7055 60.7407C94.7055 58.9918 94.489 57.5617 94.0561 56.4506C93.6438 55.3189 93.0665 54.4856 92.3243 53.9506C91.5821 53.3951 90.7059 53.1173 89.6957 53.1173C88.7267 53.1173 87.8608 53.3951 87.098 53.9506C86.3558 54.4856 85.7682 55.3189 85.3352 56.4506C84.9229 57.5617 84.7167 58.9918 84.7167 60.7407C84.7167 62.4691 84.9229 63.8992 85.3352 65.0309C85.7682 66.1626 86.3558 67.0062 87.098 67.5617C87.8608 68.0967 88.7267 68.3642 89.6957 68.3642Z"
            fill="white"
        />
        <path
            d="M101.164 66.6358V63.858L111.833 49.9383H116.132L105.617 63.858L103.607 63.2407H120.771V66.6358H101.164ZM113.039 71.5432V66.6358L113.163 63.2407V58.8889H116.936V71.5432H113.039Z"
            fill="white"
        />
        <path
            d="M70.3648 112.041L38.7227 80.8811V38.9819L70.3563 10.5699H112.272L140.194 38.9819V80.8811L112.264 112.041H70.3648Z"
            stroke="white"
            strokeWidth="2.57731"
            strokeMiterlimit="10"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1058_2520"
                x1="82.332"
                y1="145.923"
                x2="96.4061"
                y2="145.923"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E6EBF4" />
                <stop offset="0.381479" stopColor="white" />
                <stop offset="0.666667" stopColor="white" />
                <stop offset="1" stopColor="#E6EBF4" />
            </linearGradient>
        </defs>
    </svg>
);