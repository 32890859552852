import { CustomHead } from "@src/shared/ui/CustomHead";
import { NotFoundScreen } from "@pages/NotFoundScreen";

export default function Custom404() {
    return (
        <>
            <CustomHead
                title="Страница не найдена - LIKVI.COM"
                description="По какой-то причине вы попали туда, где не должны были оказаться."
                robots="noindex,follow"
            />
            <NotFoundScreen />
        </>
    );
}