import Link from "next/link";
import { RouterConfig } from "@shared/lib/routerConfig";
import { NoFavoritesIcon } from "@shared/ui/Icon/ui/Statuses/NotFoundIcon";
import { NotFoundTexts, SUPPORT_MAIL } from "./constants";
import classes from "./NotFoundScreen.module.scss";

export const NotFoundScreen = () => {
    return (
        <div className={classes.NotFoundScreen}>
            <NoFavoritesIcon />
            <div className={classes.NotFoundScreen__inner}>
                <h1 className={classes.NotFoundScreen__title}>{NotFoundTexts.TITLE}</h1>
                <p className={classes.NotFoundScreen__description}>{NotFoundTexts.DESCRIPTION}</p>
                <ul className={classes.NotFoundScreen__options}>
                    <li>{NotFoundTexts.CHECK_LINK}</li>
                    <li>
                        <a href={SUPPORT_MAIL}>{NotFoundTexts.SUPPORT}</a>
                    </li>
                </ul>
                <Link className={classes.NotFoundScreen__return} href={RouterConfig.MAIN}>
                    {NotFoundTexts.RETURN_MAIN_PAGE}
                </Link>
            </div>
        </div>
    );
};